import React from 'react';
import NumberFormat from 'react-number-format';


function SummaryInfo(props) {
  return (
    <div className="xxxx" id="summary">
      <div>
        
      </div>
    </div>
  );
}


export default SummaryInfo;