function calculateInterest (quantity, interest) {
  return quantity * interest;
}

function calculateMonth (initialDeposit,
                          contribution,
                          interest,
                          depositsAtWhatPoint,
                          lastMonthCalculated) {
  let monthResult = {
    initialDeposit: null,
    additionalDeposit: null,
    interest: null,
    total: null,
  };
  
  if (!lastMonthCalculated) {
    monthResult.initialDeposit = initialDeposit;
    monthResult.additionalDeposit = contribution;
    monthResult.interest = calculateInterest(
        depositsAtWhatPoint==='B' ? initialDeposit+contribution : initialDeposit, 
        interest
    );
  } else {
    monthResult.initialDeposit = initialDeposit;
    monthResult.additionalDeposit = lastMonthCalculated.additionalDeposit + contribution;
    monthResult.interest = lastMonthCalculated.interest + 
      calculateInterest(
        depositsAtWhatPoint==='B' ? lastMonthCalculated.total+contribution : lastMonthCalculated.total, 
        interest);
  }
  monthResult.total = monthResult.initialDeposit + monthResult.additionalDeposit + monthResult.interest;

  return monthResult;
}

function calculateMonthlyContribution(month,
                                      contribution,
                                      contributionFrequency,
                                      depositsAtWhatPoint) {

  if(contributionFrequency === 'M') return contribution;
  
  if(depositsAtWhatPoint === 'B' && month === 1) {
    return contribution;
  }

  if(depositsAtWhatPoint === 'E' && month === 12) {
    return contribution;
  }

  return 0;
}


function calculateYear (year, 
                        initialDeposit, 
                        contribution, 
                        contributionFrequency, 
                        interestRate, 
                        interestRateFrequency, 
                        previousYearCalculation,
                        depositsAtWhatPoint) {
  let yearResult = {
    name: `Year ${year}`,
    year: year,
    initialDeposit: null,
    additionalDeposit: null,
    interest: null,
    total: null,
    monthResults: null,
  };
  let monthResults = [];

  let actualInterest = interestRateFrequency === 'M' ? interestRate : interestRate/12;
  let lastMonthCalculated = null;

  if (previousYearCalculation) {
    lastMonthCalculated = previousYearCalculation.monthResults[12-1] ;
  }

  for (let month=1; month<13; month++) {
    let monthlyContribution = calculateMonthlyContribution(
      month,
      contribution,
      contributionFrequency,
      depositsAtWhatPoint
    );

    let monthCalculation = calculateMonth(initialDeposit, monthlyContribution, actualInterest, depositsAtWhatPoint, lastMonthCalculated);
    monthResults.push(monthCalculation);
    lastMonthCalculated = monthCalculation;
  }

  yearResult.monthResults = monthResults;
  yearResult.initialDeposit = lastMonthCalculated.initialDeposit;
  yearResult.additionalDeposit = lastMonthCalculated.additionalDeposit;
  yearResult.interest = lastMonthCalculated.interest;
  yearResult.total = lastMonthCalculated.total;

  return yearResult;
}


function calculateCompoundInterest (initialDeposit,
                                  timeLengthInYears,
                                  contribution,
                                  contributionFrequency,
                                  interestRate,
                                  interestRateFrequency,
                                  depositsAtWhatPoint
                                  ) {

  let result = [];
  let previousYearCalculation = null;
  for(let year=1; year<=timeLengthInYears; year++){
    result.push(calculateYear(year, 
                              initialDeposit, 
                              contribution, 
                              contributionFrequency, 
                              interestRate/100, 
                              interestRateFrequency,
                              previousYearCalculation,
                              depositsAtWhatPoint));

    previousYearCalculation = result[year-1];
  }

  return result;
}



export default calculateCompoundInterest;