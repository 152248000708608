import React from 'react';
import { Container, Row, Col, Form, Button, InputGroup, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import calculateCompoundInterest from '../../calculation/compoundInterest';


function isValidChange (e) {
  let regex2Decimals = /^\d+(\.\d{0,2})?$/g;
  let regexIntegers = /^\d+$/g;

  if ((e.target.name === 'initialDeposit' || e.target.name === 'contribution') 
      && (e.target.value === '' || regex2Decimals.test(e.target.value))) {
    
    return true;

  } else if ((e.target.name === 'contributionFrequency' || e.target.name === 'interestFrequency')) {
    
    return e.target.value === 'A' || e.target.value === 'M';

  } else if (e.target.name === 'depositsAtWhatPoint') {
    
    return e.target.value === 'B' || e.target.value === 'E';

  } else if (e.target.name === 'timeLengthInYears' 
      && (e.target.value === '' || regexIntegers.test(e.target.value))
      && Number(e.target.value) <= 500) {
    
    return true;

  } else if (e.target.name === 'interest' 
      && (e.target.value === '' || regexIntegers.test(e.target.value))
      && Number(e.target.value) <= 500) {

    return true;

  }


  
  return false;
}

function handleChange (e, currentState, setCurrentState) {
  if (isValidChange(e)) {
    let newState = {
      ...currentState,
      [e.target.name]: e.target.value,
    };
  
    newState.result = calculateCompoundInterest(
      Number(newState.initialDeposit), 
      newState.timeLengthInYears,
      Number(newState.contribution),
      newState.contributionFrequency,
      Number(newState.interest),
      newState.interestFrequency,
      newState.depositsAtWhatPoint
    );
    
    setCurrentState(newState);
  }
};


function MainForm(props) {
  return (
    <Container id="main-form">
      <Form>

        <Form.Group className="mb-5" controlId="formInitialDeposit">
          <Form.Label>Initial deposit</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control 
              type="text"
              name="initialDeposit"
              value={props.currentState.initialDeposit}
              onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)}
              />
          </InputGroup>
          <Form.Text className="text-muted">
            This is the amount of money to start.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-5" controlId="formLengthInYears">
          <Form.Label>Length of time in years</Form.Label>
          <Form.Control 
            type="text"
            name="timeLengthInYears"
            value={props.currentState.timeLengthInYears}
            onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)}
            style={{width: "50%"}} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEstimatedInterestRate">
          <Form.Label>Estimated Interest Rate</Form.Label>
          <InputGroup>
            <InputGroup.Text>%</InputGroup.Text>
            <Form.Control 
              type="text"
              name="interest"
              value={props.currentState.interest}
              onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
          </InputGroup>
          <br />
          <Container fluid>
            <Row>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="interestFrequency" 
                  label="Monthly"
                  value="M"
                  checked={props.currentState.interestFrequency === 'M' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
              </Col>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="interestFrequency"
                  label="Annually"
                  value="A"
                  checked={props.currentState.interestFrequency === 'A' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)}  />
              </Col>
            </Row>
          </Container>
        </Form.Group>
        <br />
        <Form.Group className="mb-5" controlId="formContributionAmount">
          <Form.Label>Contribution amount</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control 
              type="text"
              name="contribution"
              value={props.currentState.contribution}
              onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-5" controlId="formContributionPeriod">
          <Form.Label>Contribution period</Form.Label>
          <Container>
            <Row>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="contributionFrequency" 
                  label="Monthly"
                  value="M"
                  checked={props.currentState.contributionFrequency === 'M' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
              </Col>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="contributionFrequency" 
                  label="Annually"
                  value="A"
                  checked={props.currentState.contributionFrequency === 'A' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)}  />
              </Col>
            </Row>
          </Container>
        </Form.Group>

        <Form.Group className="mb-5" controlId="formDepositsAtWhatPoint">
          <Form.Label>Deposits made at what point in period?</Form.Label>
          <Container>
            <Row>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="depositsAtWhatPoint" 
                  label="Beginning"
                  value="B"
                  checked={props.currentState.depositsAtWhatPoint === 'B' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
              </Col>
              <Col>
                <Form.Check 
                  type="radio" 
                  name="depositsAtWhatPoint" 
                  label="End"
                  value="E"
                  checked={props.currentState.depositsAtWhatPoint === 'E' ? true : false}
                  onChange={(e) => handleChange(e, props.currentState, props.setCurrentState)} />
              </Col>
            </Row>
          </Container>
        </Form.Group>

      </Form>
    </Container>
  );
}

export default MainForm;