import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Layout from './components/Layout';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return(
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}



export default App;
