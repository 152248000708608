import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';

function MyNavbar() {
  return (
    <Navbar id="navbar" bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt="Simple compound interest calculator logo"
            src="/chart.jpg"
            width="30"
            height="30"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#projection-breakdown">Projection Breakdown</Nav.Link>
          </Nav>
          <Nav className="navbar-language">
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;