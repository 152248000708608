import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import NumberFormat from 'react-number-format';


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
      return (
          <div className="custom-tooltip">
              <p style={{color: payload[0].color}}>
                ${payload[0].name} 
                : <NumberFormat value={Number(payload[0].value)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true} />
              </p>
              <p style={{color: payload[1].color}}>
                ${payload[1].name} 
                : <NumberFormat value={Number(payload[1].value)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true} />
              </p>
              <p style={{color: payload[2].color}}>
                ${payload[2].name} 
                : <NumberFormat value={payload[2].value.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true} />
              </p>
              <p>
                Total: <NumberFormat value={(Number(payload[0].value) + Number(payload[1].value) + Number(payload[2].value))} displayType={'text'} thousandSeparator={true} prefix={'$'}  decimalScale={2} fixedDecimalScale={true} />
              </p>
          </div>
      );
  }

  return null;
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}

function calculateInterval(dataLength) {
  if (dataLength < 20) {
    return 0;
  } else if (dataLength >= 20 && dataLength < 50) {
    return 1;
  } else {
    return 2;
  }
  
}



function MainChart(props) {
  if (props.data.length === 0) {
    return(<span></span>);
  } else {
    return (
      <ResponsiveContainer aspect={1.3}>
        <BarChart
          width={1000}
          height={700}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={calculateInterval(props.data.length)} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Bar dataKey="initialDeposit" name="Initial deposit" stackId="a" fill="#005B9A" />
          <Bar dataKey="additionalDeposit" name="Additional deposit" stackId="a" fill="#0191C8" />
          <Bar dataKey="interest" name="Interest" stackId="a" fill="#74C2E1" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
  
  
export default MainChart;