import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import calculateCompoundInterest from '../../calculation/compoundInterest';
import MainForm from '../../components/MainForm';
import MainChart from '../../components/MainChart';
import DetailsTable from '../../components/DetailsTable';
import SummaryInfo from '../../components/SummaryInfo';


const INITIAL_STATE = {
  initialDeposit: '100',
  timeLengthInYears: '10',
  contribution: '1',
  contributionFrequency: 'M', //M = Monthly, A = Annually
  interest: '1',
  interestFrequency: 'M', //M = Monthly, A = Annually
  depositsAtWhatPoint: 'B', //B = Beginning, E = End
  result: []
};


function Home() {
  let initialState = INITIAL_STATE;
  initialState.result = calculateCompoundInterest(
    Number(initialState.initialDeposit),
    initialState.timeLengthInYears,
    Number(initialState.contribution),
    initialState.contributionFrequency,
    Number(initialState.interest),
    initialState.interestFrequency,
    initialState.depositsAtWhatPoint);

  const [currentState, setCurrentState] = useState(initialState);
  
  return (
    <Container id="home" fluid="xxl">
      <Row>
        <Col className="main-description" md={12}>
          <h1>Simple Compound Interest Calculator</h1>
          <p>
            Find out how your investment can grow through compound interest over time. Use the form to enter the required information to do the calculation.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={4}>
          <MainForm currentState={currentState} 
            setCurrentState={setCurrentState}  />
        </Col>
        <Col lg={9} md={8}>
          <MainChart data={currentState.result} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <SummaryInfo data={currentState.result} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DetailsTable data={currentState.result} />
        </Col>
      </Row>
    </Container>
  );
}



export default Home;