import React from 'react';
import NumberFormat from 'react-number-format';


function DetailsTable(props) {
  return (
    <div className="details-table-container" id="projection-breakdown">
      <h2>Projection breakdown</h2>

      <table className="details-table">
        <thead>
          <tr>
            <td>
              Year
            </td>
            <td>
              Initial deposit
            </td>
            <td>
              Additional deposit
            </td>
            <td>
              Interest
            </td>
            <td>
              Total
            </td>
          </tr>
        </thead>
        <tbody>
          {props.data.map(elem => (
              <tr key={elem.year}>
                <td>
                  {elem.year}
                </td>
                <td>
                  <NumberFormat value={Number(elem.initialDeposit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </td>
                <td>
                  <NumberFormat value={Number(elem.additionalDeposit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </td>
                <td>
                  <NumberFormat value={Number(elem.interest).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </td>
                <td>
                <NumberFormat value={Number(elem.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </td>
              </tr>
            ))}

        </tbody>
      </table>
    </div>
  );
}


export default DetailsTable;